
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ResetPasswordRequestDto } from "../../core/types/UserTypes";

export default defineComponent({
  name: "user-account-settings",
  props: ["accountid", "clientuser"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    const resetPassword = ref<ResetPasswordRequestDto>({
      id: props.accountid,
      password: "",
    });

    const submitProfileButton = ref<HTMLElement | null>(null);
    const submitDeactiveButton = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required().label("First name"),
      lastName: Yup.string().required().label("Last name"),
    });

    const changeEmailValidator = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePasswordValidator = Yup.object().shape({
      //Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      newpassword: Yup.string()
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
        )
        .label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const hireStatuses = computed(() => {
      return [
        { hireStatusText: "Pending", hireStatusValue: 5 },
        { hireStatusText: "Hired", hireStatusValue: 10 },
        { hireStatusText: "Terminated", hireStatusValue: 15 },
        { hireStatusText: "NotHired", hireStatusValue: 20 },
      ];
    });

    const userRoles = computed(() => {
      if (currentUser.role === "SuperAdmin") {
        return [
          { roleName: "SuperAdmin", roleValue: "SuperAdmin" },
          {
            roleName: "Licensed Distributor",
            roleValue: "LicensedDistributor",
          },
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "LicensedDistributor") {
        return [
          {
            roleName: "Licensed Distributor Associate",
            roleValue: "LDAssociate",
          },
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "LDAssociate") {
        return [
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "PowerUser") {
        return [
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "ClientUser") {
        return [
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      }
      return [];
    });

    const deactiveBtnClass = computed(() => {
      if (accountInfo.value.archive) {
        return "btn-success";
      } else {
        return "btn-danger";
      }
    });

    const deactiveBtnLabel = computed(() => {
      if (accountInfo.value.archive) {
        return "Activate Account";
      } else {
        return "Deactivate Account";
      }
    });
    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Settings", ["User"]);
    });
    const saveProfileChanges = () => {
      if (submitProfileButton.value) {
        // Activate indicator
        submitProfileButton.value?.setAttribute("data-kt-indicator", "on");
        // Send login request
        store
          .dispatch(Actions.UPDATE_ACCOUNTPROFILE, accountInfo.value)
          .then(() => {
            Swal.fire({
              text: "Success. The user's profile has been updated.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem saving your updates!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitProfileButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const deactivateAccount = () => {
      if (submitDeactiveButton.value) {
        // Activate indicator
        submitDeactiveButton.value.setAttribute("data-kt-indicator", "on");
        accountInfo.value.archive = !accountInfo.value.archive;

        store
          .dispatch(Actions.DEACTIVATE_REACTIVATE_USER, accountInfo.value)
          .then(() => {
            if (accountInfo.value.archive) {
              Swal.fire({
                text: "Confirmed. The account has been deactivated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            } else {
              Swal.fire({
                text: "Confirmed. The account has been activated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem deactivating the account.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitDeactiveButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        resetPassword.value.password = accountInfo.value.password;

        store
          .dispatch(Actions.RESET_PASSWORD, resetPassword.value)
          .then(() => {
            Swal.fire({
              text: "Confirmed. The password has been reset.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem changing the password.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
        passwordFormDisplay.value = !passwordFormDisplay.value;
      }
    };

    watch(accountInfo, () => {
      isDataReady.value = true;
    });
    return {
      currentUser,
      isDataReady,
      submitProfileButton,
      submitDeactiveButton,
      saveProfileChanges,
      hireStatuses,
      userRoles,
      deactivateAccount,
      deactiveBtnClass,
      deactiveBtnLabel,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmailValidator,
      changePasswordValidator,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      accountInfo,
      resetPassword,
    };
  },
});
