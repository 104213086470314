<template>
  <div>
    <!--begin::Basic info-->
    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div
        class="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Profile Details</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Content-->
      <div id="kt_account_profile_details" class="collapse show">
        <!--begin::Form-->
        <Form
          id="kt_account_profile_details_form"
          class="form"
          novalidate="novalidate"
          @submit="saveProfileChanges"
          :validation-schema="profileDetailsValidator"
        >
          <!--begin::Card body-->
          <div class="card-body border-top p-9">
            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >Full Name</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8">
                <!--begin::Row-->
                <div class="row">
                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                      type="text"
                      name="firstName"
                      class="
                        form-control form-control-lg form-control-solid
                        mb-3 mb-lg-0
                      "
                      placeholder="First name"
                      v-model="accountInfo.firstName"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="firstName" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->

                  <!--begin::Col-->
                  <div class="col-lg-6 fv-row">
                    <Field
                      type="text"
                      name="lastName"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Last name"
                      v-model="accountInfo.lastName"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="lastName" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                <span>Job Title</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Job Title"
                ></i>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  type="text"
                  name="jobTitle"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Job Title"
                  v-model="accountInfo.jobTitle"
                />
                <!--<div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                <span>Company</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Company"
                ></i>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  type="text"
                  name="companyName"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Company Name"
                  v-model="accountInfo.companyName"
                />
                <!--<div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                <span>Mobile Phone</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  type="tel"
                  name="phone"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Phone number"
                  v-model="accountInfo.cellPhone"
                />
                <!--<div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div
              class="row mb-6"
              v-if="
                currentUser.role === 'SuperAdmin' ||
                currentUser.role === 'LicensedDistributor' ||
                currentUser.role === 'LDAssociate' ||
                currentUser.role === 'PowerUser'
              "
            >
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                <span>Email Notifications</span>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row pt-2">
                <div class="form-check form-check-custom form-check-solid">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="accountInfo.surveyEmailNotifications"
                    id="surveyEmailNote"
                    :checked="accountInfo.surveyEmailNotifications"
                  />
                  <label class="form-check-label" for="surveyEmailNote"
                    >Survey Notifications
                    <i
                      class="fas fa-exclamation-circle ms-1 fs-7"
                      data-bs-toggle="tooltip"
                      title="Receive email notification when an emailed survey is completed"
                    ></i
                  ></label>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-6" v-if="accountid !== currentUser.id">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6"
                >Employment Status</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <!--begin::Options-->
                <div class="d-flex align-items-center mt-3">
                  <Field
                    name="employment"
                    class="form-select form-select-solid"
                    as="select"
                    v-model="accountInfo.hireStatusValue"
                  >
                    <option
                      v-for="item in hireStatuses"
                      :key="item.hireStatusValue"
                      :label="item.hireStatusText"
                      :value="item.hireStatusValue"
                    ></option>
                  </Field>
                </div>
                <!--end::Options-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-6" v-if="accountid !== currentUser.id">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">Role</label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <!--begin::Options-->
                <div class="d-flex align-items-center mt-3">
                  <Field
                    name="role"
                    class="form-select form-select-solid"
                    as="select"
                    v-model="accountInfo.userRoleName"
                  >
                    <option
                      v-for="item in userRoles"
                      :key="item.roleValue"
                      :label="item.roleName"
                      :value="item.roleValue"
                    ></option>
                  </Field>
                </div>
                <!--end::Options-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>
          <!--end::Card body-->

          <!--begin::Actions-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="reset"
              class="btn btn-white btn-active-light-primary me-2"
            >
              Discard
            </button>

            <button
              id="kt_account_profile_details_submit"
              ref="submitProfileButton"
              type="submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Save Changes </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Basic info-->

    <!--begin::Sign-in Method-->
    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div
        class="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_signin_method"
      >
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Sign-in Method</h3>
        </div>
      </div>
      <!--end::Card header-->

      <!--begin::Content-->
      <div id="kt_account_signin_method" class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <!--begin::Email Address-->
          <div class="d-flex flex-wrap align-items-center mb-8">
            <div id="kt_signin_email" class="">
              <div class="fs-4 fw-boldest mb-1">Email Address</div>
              <div class="fs-6 fw-bold text-gray-600">
                {{ accountInfo.email }}
              </div>
            </div>
          </div>
          <!--end::Email Address-->

          <!--begin::Password-->
          <div class="d-flex flex-wrap align-items-center mb-8">
            <div
              id="kt_signin_password"
              :class="{ 'd-none': passwordFormDisplay }"
            >
              <div class="fs-4 fw-boldest mb-1">Password</div>
              <div class="fs-6 fw-bold text-gray-600">************</div>
            </div>
            <div
              id="kt_signin_password_edit"
              class="flex-row-fluid"
              :class="{ 'd-none': !passwordFormDisplay }"
            >
              <div class="fs-6 fw-bold text-gray-600 mb-4">
                <small
                  >Must Contain 6 Characters, One Uppercase, One Lowercase, One
                  Number and one special case Character</small
                >
              </div>

              <!--begin::Form-->
              <Form
                id="kt_signin_change_password"
                class="form"
                novalidate="novalidate"
                @submit="updatePassword()"
                :validation-schema="changePasswordValidator"
              >
                <div class="row mb-6">
                  <div class="col-lg-4">
                    <div class="fv-row mb-0">
                      <label
                        for="newpassword"
                        class="form-label fs-6 fw-bolder mb-3"
                        >New Password</label
                      >
                      <Field
                        type="password"
                        class="
                          form-control form-control-lg form-control-solid
                          fw-bold
                          fs-6
                        "
                        name="newpassword"
                        id="newpassword"
                        v-model="accountInfo.password"
                      />
                      <small
                        >Must Contain 6 Characters, One Uppercase, One
                        Lowercase, One Number and one special case
                        Character</small
                      >
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="newpassword" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="fv-row mb-0">
                      <label
                        for="confirmpassword"
                        class="form-label fs-6 fw-bolder mb-3"
                        >Confirm New Password</label
                      >
                      <Field
                        type="password"
                        class="
                          form-control form-control-lg form-control-solid
                          fw-bold
                          fs-6
                        "
                        name="confirmpassword"
                        id="confirmpassword"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="confirmpassword" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    id="kt_password_submit"
                    type="submit"
                    ref="updatePasswordButton"
                    class="btn btn-primary me-2 px-6"
                  >
                    <span class="indicator-label"> Update Password </span>
                    <span class="indicator-progress">
                      Please wait...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                  <button
                    id="kt_password_cancel"
                    type="button"
                    @click="passwordFormDisplay = !passwordFormDisplay"
                    class="btn btn-color-gray-400 btn-active-light-primary px-6"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
              <!--end::Form-->
            </div>
            <div
              id="kt_signin_password_button"
              class="ms-auto"
              :class="{ 'd-none': passwordFormDisplay }"
            >
              <button
                @click="passwordFormDisplay = !passwordFormDisplay"
                class="btn btn-light fw-boldest"
              >
                Reset Password
              </button>
            </div>
          </div>
          <!--end::Password-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Sign-in Method-->
    <!--begin::Deactivate Account-->
    <div class="card mb-5 mb-xl-10" v-if="accountid !== currentUser.id">
      <!--begin::Card header-->
      <div
        class="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate"
        aria-expanded="true"
        aria-controls="kt_account_deactivate"
      >
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Deactivate/Reactivate Account</h3>
        </div>
      </div>
      <!--end::Card header-->

      <!--begin::Content-->
      <div id="kt_account_deactivate" class="collapse show">
        <!--begin::Form-->
        <form
          id="kt_account_deactivate_form"
          class="form"
          @submit.prevent="deactivateAccount()"
        >
          <!--begin::Card footer-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button
              id="kt_account_deactivate_account_submit"
              ref="submitDeactiveButton"
              type="submit"
              :class="deactiveBtnClass"
              class="btn fw-bold"
            >
              <span class="indicator-label"> {{ deactiveBtnLabel }} </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Card footer-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Deactivate Account-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ResetPasswordRequestDto } from "../../core/types/UserTypes";

export default defineComponent({
  name: "user-account-settings",
  props: ["accountid", "clientuser"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const currentUser = store.getters.currentUser;

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    const resetPassword = ref<ResetPasswordRequestDto>({
      id: props.accountid,
      password: "",
    });

    const submitProfileButton = ref<HTMLElement | null>(null);
    const submitDeactiveButton = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required().label("First name"),
      lastName: Yup.string().required().label("Last name"),
    });

    const changeEmailValidator = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePasswordValidator = Yup.object().shape({
      //Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      newpassword: Yup.string()
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
        )
        .label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const hireStatuses = computed(() => {
      return [
        { hireStatusText: "Pending", hireStatusValue: 5 },
        { hireStatusText: "Hired", hireStatusValue: 10 },
        { hireStatusText: "Terminated", hireStatusValue: 15 },
        { hireStatusText: "NotHired", hireStatusValue: 20 },
      ];
    });

    const userRoles = computed(() => {
      if (currentUser.role === "SuperAdmin") {
        return [
          { roleName: "SuperAdmin", roleValue: "SuperAdmin" },
          {
            roleName: "Licensed Distributor",
            roleValue: "LicensedDistributor",
          },
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "LicensedDistributor") {
        return [
          {
            roleName: "Licensed Distributor Associate",
            roleValue: "LDAssociate",
          },
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "LDAssociate") {
        return [
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "PowerUser") {
        return [
          { roleName: "Client Power User", roleValue: "PowerUser" },
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      } else if (currentUser.role === "ClientUser") {
        return [
          { roleName: "Client User", roleValue: "ClientUser" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      }
      return [];
    });

    const deactiveBtnClass = computed(() => {
      if (accountInfo.value.archive) {
        return "btn-success";
      } else {
        return "btn-danger";
      }
    });

    const deactiveBtnLabel = computed(() => {
      if (accountInfo.value.archive) {
        return "Activate Account";
      } else {
        return "Deactivate Account";
      }
    });
    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Settings", ["User"]);
    });
    const saveProfileChanges = () => {
      if (submitProfileButton.value) {
        // Activate indicator
        submitProfileButton.value?.setAttribute("data-kt-indicator", "on");
        // Send login request
        store
          .dispatch(Actions.UPDATE_ACCOUNTPROFILE, accountInfo.value)
          .then(() => {
            Swal.fire({
              text: "Success. The user's profile has been updated.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem saving your updates!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitProfileButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const deactivateAccount = () => {
      if (submitDeactiveButton.value) {
        // Activate indicator
        submitDeactiveButton.value.setAttribute("data-kt-indicator", "on");
        accountInfo.value.archive = !accountInfo.value.archive;

        store
          .dispatch(Actions.DEACTIVATE_REACTIVATE_USER, accountInfo.value)
          .then(() => {
            if (accountInfo.value.archive) {
              Swal.fire({
                text: "Confirmed. The account has been deactivated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            } else {
              Swal.fire({
                text: "Confirmed. The account has been activated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem deactivating the account.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitDeactiveButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        resetPassword.value.password = accountInfo.value.password;

        store
          .dispatch(Actions.RESET_PASSWORD, resetPassword.value)
          .then(() => {
            Swal.fire({
              text: "Confirmed. The password has been reset.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem changing the password.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
        passwordFormDisplay.value = !passwordFormDisplay.value;
      }
    };

    watch(accountInfo, () => {
      isDataReady.value = true;
    });
    return {
      currentUser,
      isDataReady,
      submitProfileButton,
      submitDeactiveButton,
      saveProfileChanges,
      hireStatuses,
      userRoles,
      deactivateAccount,
      deactiveBtnClass,
      deactiveBtnLabel,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmailValidator,
      changePasswordValidator,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      accountInfo,
      resetPassword,
    };
  },
});
</script>
